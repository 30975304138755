/* Custom scrollbar styles for the sidebar */
.sidebar {
    overflow-y: auto;
    /* Enable vertical scrolling */
}

/* Scrollbar styling for Webkit browsers (Chrome, Safari) */
.sidebar::-webkit-scrollbar {
    width: 6px;
    /* Width of the scrollbar */
}

.sidebar::-webkit-scrollbar-track {
    background: transparent;
    /* Track color */
}

.sidebar::-webkit-scrollbar-thumb {
    background: #ccc;
    /* Scrollbar color */
    border-radius: 3px;
    /* Rounded corners */
}

.sidebar::-webkit-scrollbar-thumb:hover {
    background: #888;
    /* Scrollbar color on hover */
}

/* Make sure to hide scrollbar for other browsers */
.sidebar {
    scrollbar-width: thin;
    /* For Firefox */
    scrollbar-color: #ccc transparent;
    /* For Firefox */
}