@tailwind base;
@tailwind components;
@tailwind utilities;

/* Variables for Light Theme */
html {
  --bg-color: white;
  --text-color: black;
  --card-bg-color: #ffffff;
  --header-logo-light: url('../public/assets/images/logo1.png');
  /* Light theme logo */
  --header-logo-dark: url('../public/assets/images/white.png');
  /* Dark theme logo */
}

/* Variables for Dark Theme */
html.dark {
  --bg-color: #020617;
  --text-color: white;
  --card-bg-color: #071426;
}

body {
  background-color: var(--bg-color);
  color: var(--text-color);
}

.card {
  background: var(--card-bg-color);
  padding: 2rem;
  border-radius: 10px;
  margin-bottom: 1rem;
}

/* Header Logo for Light and Dark Theme */
.header-logo {
  background-image: var(--header-logo-light);
  background-size: contain;
  background-repeat: no-repeat;
  width: 220px;
  /* Increase width as needed */
  height: 40px;

}

html.dark .header-logo {
  background-image: var(--header-logo-dark);
}

/* Dark Mode-specific Body background */
html.dark body {
  background: var(--bg-color);
}

/* Ticker */
.ticker {
  display: inline-block;
  animation: ticker-scroll 10s linear infinite;
}

@keyframes ticker-scroll {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(-100%);
  }
}

/* Carousel Item Styling */
.carousel-item {
  @apply w-24 h-24 flex items-center justify-center bg-gray-200 dark:bg-gray-700 rounded-full shadow-lg;
}

.carousel-item img {
  @apply w-12 h-12;
}

/* Thin Tag Styling */
.thin-tag {
  padding: 1px 8px !important;
  font-size: 0.75rem;
  line-height: 1rem;
}

.move-left {
  margin-left: -20px;
  /* Adjust the value as needed */
}

/* Custom scrollbar styling */
::-webkit-scrollbar {
  width: 8px;
  /* Adjust the width of the scrollbar */
}

::-webkit-scrollbar-thumb {
  background-color: #ccc;
  /* Color of the scrollbar thumb */
  border-radius: 10px;
  /* Rounded corners for the scrollbar thumb */
}

::-webkit-scrollbar-track {
  background: transparent;
  /* Background for the scrollbar track */
}